<template>
    <div>
        <v-dialog v-model="dialog" class="dialogbox">
            <template v-slot:activator="{ attrs }">
                <button class="buttonclass special" v-bind="attrs" @click.stop="dialog = true">
                    Share with friends!
                </button>
            </template>

            <v-card class="explanation">

                <!-- <v-spacer></v-spacer>-->

                <v-card-text class="stuffafter"> 
                    If you want to share on social media, you`ll have to accept cookies. If you`d rather not, just <a class="clickableClass" @click="copyLink()" id="clipboardLink">click here</a> to copy the link to your clipboard and write your own social media post!
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="goToSharePage()">
                        ok, share
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        close
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>


export default {
    name: 'SharePopup',
    emits: ["advance-to-next-slide"],
    props: {
        win: Boolean
    },
    methods: {
        openExternalLink(link) {
            window.open(link, '_blank')
        },
        copyLink() {
            navigator.clipboard.writeText("https://www.fossilgame.org");
        },
        goToSharePage() {
            this.dialog = false;
            location.href='./share.html'
        }
    },
    data() {
        return {
            dialog: false,
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.dialogbox{
    max-width: 600px;
    max-height: 100vh;
}




.stuffafter {
    z-index: 0;
    position: relative;
    margin-top: -2%;
    overflow-y: scroll;
}


.clickableClass{
    font-weight: bold; 
    color: black; 
    cursor: pointer;
}

/* mouse over link */
a:hover {
  color: #921e1e;
}

/* selected link */
a:active {
  color: #f9241d;
}


.stuffafter::-webkit-scrollbar {
  width: 0.6em;
  margin: 0.2em;
  padding: 0.2em;
}
 
.stuffafter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(192, 182, 182, 0.3);
}
 
.stuffafter::-webkit-scrollbar-thumb {
  background-color: #545050;
  /*outline: 1px solid rgb(41, 87, 133);*/
  /*border-radius: 0.25em;*/
  border-right: 0.2em solid #f1bb70
}

.stuffOnTop {
    border: 2px solid #000000;
    z-index: 1;
    position: float;
    background-color: white;
    font-size: xx-small;
    font-family: sans-serif
}



.img3 {
    width: 33%;
}

.img2 {
    width: 50%;

}
.img1 {
    width: 100%;

}

.imgInside {
    width: 100%
}

.txtInside {
    padding: 2% 5%;
    line-height: normal;
}


.explanation {
    width: 100%;
    max-width: 490pt;
    padding: 0pt;
    background-color: #f1bb70;
    font-family: 'Courier New', Courier, monospace;
    margin-left: auto;
    margin-right: auto;
    max-height: 80vh;

}
</style>
