<template>
  <!-- tsParticles Repository: https://github.com/matteobruni/tsparticles -->
  <!-- tsParticles Website: https://particles.js.org -->
  <Particles id="tsparticles" :options="options" :particlesInit="particlesInit" />
</template>

<script>
//import { loadSlim } from "tsparticles-slim"; // loads tsparticles-slim
import { loadFull } from "tsparticles"; // loads tsparticles

export default {
  data() {
    return {
      // using an empty options object will load the default options, which are static particles with no background and 3px radius, opacity 100%, white color
      // all options can be found here: https://particles.js.org/docs/interfaces/Options_Interfaces_IOptions.IOptions.html
      options: {
        background: {
          //color: "#000", // this sets a background color for the canvas
        },
        fullScreen: {
          enable: true, // enabling this will make the canvas fill the entire screen, it's enabled by default
          zIndex: 1, // this is the z-index value used when the fullScreen is enabled, it's 0 by default
        },
        interactivity: {
          events: {
            onClick: {
              enable: false, // enables the click event, it's disabled by default
              mode: "push", // adds the particles on click
            },
            onHover: {
              enable: false, // enables the mouse hover event, it's disabled by default
              mode: "repulse", // repulse the particles on mouse hover
            },
          },
          modes: {
            push: {
            //  quantity: 10, // number of particles to add on click
            },
            repulse: {
            //  distance: 300, // distance of the particles from the cursor
            },
          },
        },
        particles: {
          "color": {
            "value": [
              "#FFFFFF",
              "#d700FF",
              "#00FFd7",
              "#FFd700"
            ]
          },
          "move": {
            "direction": "bottom",
            "enable": true,
            "outModes": {
              "default": "out"
            },
            "size": true,
            "speed": {
              "min": 7,
              "max": 8
            }
          },
          "number": {
            "value": 50,
            "density": {
              "enable": true,
              "area": 800
            }
          },
          "opacity": {
            "value": 1,
            "animation": {
              "enable": false,
              "startValue": "max",
              "destroy": "min",
              "speed": 0.3,
              "sync": true
            }
          },
          "rotate": {
            "value": {
              "min": 0,
              "max": 360
            },
            "direction": "random",
            "move": true,
            "animation": {
              "enable": true,
              "speed": 30
            }
          },
          "tilt": {
            "direction": "random",
            "enable": true,
            "move": true,
            "value": {
              "min": 0,
              "max": 360
            },
            "animation": {
              "enable": true,
              "speed": 2
            }
          },
          "shape": {
            "type": [
              "image"
            ],
            "options": {
              "image": [
          {
            "src": "./dollarBillSmall-1.png",
            "width": 50,
            "height":90,
          }]
            }
          },
          "size": {
            "value": {
              "min": 20,
              "max": 30
            }
          },
          "roll": {
            "animation": {
              "enable": true,
              "speed": 10
            },
            "direction": "random",
            "move": true,

            "darken": {
              "enable": true,
              "value": 30
            },
            "enlighten": {
              "enable": true,
              "value": 30
            },
            "enable": true,
            "speed": {
              "min": 15,
              "max": 25
            }
          },
          "wobble": {
            "distance": 5,
            "enable": true,
            "move": true,
            "speed": {
              "min": -5,
              "max": 5
            }
          }
        },
      },
    };
  },
  methods: {
    async particlesInit(engine) {
    //  await loadSlim(engine);
      await loadFull(engine);
      // await loadFull(engine); // for this sample the slim version is enough, choose whatever you prefer, slim is smaller in size but doesn't have all the plugins and the mouse trail feature
    },
  },
};
</script>
