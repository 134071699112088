<template>
    <div>
        <v-dialog v-model="dialog" class="dialogbox">
            <template v-slot:activator="{ attrs }">
                <button class="buttonclass doesthishappen" v-bind="attrs" @click.stop="dialog = true">
                    {{ truth.question }}
                </button>
            </template>

            <v-card class="explanation">
                <div class="wrapper-images clear">
                    <div class="container forOneArticle topLayer" v-if="truth.choices.length == 1">
                        <div class="rot0 stuffOnTop img1" @click="openExternalLink(truth.choices[0].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[0].img)">
                            <h3 class="txtInside">{{ truth.choices[0].title }}</h3>
                            <p class="txtInside">{{ truth.choices[0].source }} | {{ truth.choices[0].blurb }}</p>
                        </div>
                    </div>
                    <div class="container forTwoArticles topLayer" v-else-if="truth.choices.length == 2">
                        <div class="rot-10 stuffOnTop img2" @click="openExternalLink(truth.choices[0].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[0].img)">
                            <h3 class="txtInside">{{ truth.choices[0].title }}</h3>
                            <p class="txtInside">{{ truth.choices[0].source }} | {{ truth.choices[0].blurb }}</p>
                        </div>
                        <div class="rot10 stuffOnTop img2" @click="openExternalLink(truth.choices[1].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[1].img)">
                            <h3 class="txtInside">{{ truth.choices[1].title }}</h3>
                            <p class="txtInside">{{ truth.choices[1].source }} | {{ truth.choices[1].blurb }}</p>
                        </div>
                    </div>
                    <div class="container topLayer" v-else>
                        <div class="rot-20 stuffOnTop img3" @click="openExternalLink(truth.choices[0].link)"
                            type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[0].img)">
                            <h3 class="txtInside">{{ truth.choices[0].title }}</h3>
                            <p class="txtInside">{{ truth.choices[0].source }} | {{ truth.choices[0].blurb }}</p>
                        </div>
                        <div class="rot-5 stuffOnTop img3" @click="openExternalLink(truth.choices[1].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[1].img)">
                            <h3 class="txtInside">{{ truth.choices[1].title }}</h3>
                            <p class="txtInside">{{ truth.choices[1].source }} | {{ truth.choices[1].blurb }}</p>
                        </div>
                        <div class="rot15 stuffOnTop img3" @click="openExternalLink(truth.choices[2].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[2].img)">
                            <h3 class="txtInside">{{ truth.choices[2].title }}</h3>
                            <p class="txtInside">{{ truth.choices[2].source }} | {{ truth.choices[2].blurb }}</p>
                        </div>
                    </div>
                    <div class="container pushUp forOneArticle" v-if="truth.choices.length == 4">
                        <div class="rot5 stuffOnTop img1" @click="openExternalLink(truth.choices[3].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[3].img)">
                            <h3 class="txtInside">{{ truth.choices[3].title }}</h3>
                            <p class="txtInside">{{ truth.choices[3].source }} | {{ truth.choices[3].blurb }}</p>
                        </div>
                    </div>
                    <div class="container pushUp forTwoArticles" v-else-if="truth.choices.length == 5">
                        <div class="rot-10 stuffOnTop img2" @click="openExternalLink(truth.choices[3].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[3].img)">
                            <h3 class="txtInside">{{ truth.choices[3].title }}</h3>
                            <p class="txtInside">{{ truth.choices[3].source }} | {{ truth.choices[3].blurb }}</p>
                        </div>
                        <div class="rot10 stuffOnTop img2" @click="openExternalLink(truth.choices[4].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[4].img)">
                            <h3 class="txtInside">{{ truth.choices[4].title }}</h3>
                            <p class="txtInside">{{ truth.choices[4].source }} | {{ truth.choices[4].blurb }}</p>
                        </div>
                    </div>
                    <div class="container pushUp" v-else-if="truth.choices.length == 6">
                        <div class="rot-15 stuffOnTop img3" @click="openExternalLink(truth.choices[3].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[3].img)">
                            <h3 class="txtInside">{{ truth.choices[3].title }}</h3>
                            <p class="txtInside">{{ truth.choices[3].source }} | {{ truth.choices[3].blurb }}</p>
                        </div>
                        <div class="rot5 stuffOnTop img3" @click="openExternalLink(truth.choices[4].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[4].img)">
                            <h3 class="txtInside">{{ truth.choices[4].title }}</h3>
                            <p class="txtInside">{{ truth.choices[4].source }} | {{ truth.choices[4].blurb }}</p>
                        </div>
                        <div class="rot25 stuffOnTop img3" @click="openExternalLink(truth.choices[5].link)" type="button">
                            <img class="imgInside" :src="require('../assets/' + truth.choices[5].img)">
                            <h3 class="txtInside">{{ truth.choices[5].title }}</h3>
                            <p class="txtInside">{{ truth.choices[5].source }} | {{ truth.choices[5].blurb }}</p>
                        </div>
                    </div>
                </div>
                <!-- <v-spacer></v-spacer>-->

                <v-card-text class="stuffafter" v-html="truth.text"></v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">
                        close
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>


export default {
    name: 'StoryPage',
    emits: ["advance-to-next-slide"],
    props: {
        truth: {
            type: Object,
            default() {
                return {

                    text: "dummyTruthText",
                    question: "dummyTruthQuestion",
                    link: "fakeLink",
                    img: "fakeImg.png"
                }
            }
        }
    },
    methods: {
        openExternalLink(link) {
            window.open(link, '_blank')
        }
    },
    data() {
        return {
            dialog: false,
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.dialogbox{
    max-width: 600px;
    max-height: 100vh;
}
.doesthishappen {
    font-weight: bold;
    text-decoration: underline;
    z-index: 7;
    position: relative;
}

/* ROTATING CLASSES */

.rot0 {
    left: 30%;
    right: 0%
}


.rot-20 {
    -webkit-transform: rotate(340deg);
    -moz-transform: rotate(340deg);
    -o-transform: rotate(340deg);
    -ms-transform: rotate(340deg);
    transform: rotate(340deg);
    float: left;
}

.rot10 {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    float: right
}

.rot15 {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    float: right
}

.rot-10 {
    -webkit-transform: rotate(350deg);
    -moz-transform: rotate(350deg);
    -o-transform: rotate(350deg);
    -ms-transform: rotate(350deg);
    transform: rotate(350deg);
    float: left
}

.rot-15 {
    -webkit-transform: rotate(345deg);
    -moz-transform: rotate(345deg);
    -o-transform: rotate(345deg);
    -ms-transform: rotate(345deg);
    transform: rotate(345deg);
    float: left;
}

.rot25 {
    -webkit-transform: rotate(25deg);
    -moz-transform: rotate(25deg);
    -o-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    transform: rotate(25deg);
    float: right
}

.rot5 {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    float: left;
}

.rot-5 {
    -webkit-transform: rotate(355deg);
    -moz-transform: rotate(355deg);
    -o-transform: rotate(355deg);
    -ms-transform: rotate(355deg);
    transform: rotate(355deg);
    float: left
}

/* THE REST OF THE CLASSES */

.container {
    vertical-align: bottom;
    z-index: 0;
    overflow: hidden;
    position: relative;
    padding-bottom: 0%;
}


.wrapper-images {
    overflow: hidden;
    position: static;
    min-height: 150px;
    flex-shrink: 0;
}

.pushUp {
    margin-top: max(-16vw, -70px);
    overflow: visible;
    padding-bottom: 5%;
    margin-bottom: -2%
}

.topLayer {
    padding-bottom: 5%
}

.forTwoArticles {
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    overflow: visible;
    width: 70%;
}
.forOneArticle {
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    overflow: visible;
    width: 35%;
}


.stuffafter {
    z-index: 0;
    position: relative;
    margin-top: -2%;
    overflow-y: scroll;
}


.stuffafter::-webkit-scrollbar {
  width: 0.6em;
  margin: 0.2em;
  padding: 0.2em;
}
 
.stuffafter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(192, 182, 182, 0.3);
}
 
.stuffafter::-webkit-scrollbar-thumb {
  background-color: #545050;
  /*outline: 1px solid rgb(41, 87, 133);*/
  /*border-radius: 0.25em;*/
  border-right: 0.2em solid #f1bb70
}

.stuffOnTop {
    border: 2px solid #000000;
    z-index: 1;
    position: float;
    background-color: white;
    font-size: xx-small;
    font-family: sans-serif
}



.img3 {
    width: 33%;
}

.img2 {
    width: 50%;

}
.img1 {
    width: 100%;

}

.imgInside {
    width: 100%
}

.txtInside {
    padding: 2% 5%;
    line-height: normal;
}


.explanation {
    width: 100%;
    max-width: 490pt;
    padding: 0pt;
    background-color: #f1bb70;
    font-family: 'Courier New', Courier, monospace;
    margin-left: auto;
    margin-right: auto;
    max-height: 80vh;

}
</style>
