<template>
    <div>
        <div class="imagebackgroundStory">
            <img :src="require('../assets/' + slideImage)" class="hexagon">
        </div>
        <div v-if="slide.layout == 'text'" class="textcontentPlay">
            <div class="insidecontent">
                <h2 class="textbox" v-html="this.customisedText"></h2>

                <TruthCheck v-if="slide.truthCheck" :truth="slide.truth" />

                <div v-if="slide.responseType === 'simple'">
                    <button class="buttonclass play" @click="setDesiredPost(slide.choices[0])">
                        Ok, next
                    </button>
                </div>
                <div v-else-if="slide.responseType === 'multiple'">
                    <template v-for="choice in slide.choices" :key="choice.goTo">
                        <button class="buttonclass play" @click="setDesiredPost(choice)">{{ choice.text }}</button>
                    </template>
                </div>
            </div>
        </div>

        <div v-else class="textcontentSpecial">

            <div class="insidecontent">
                <h2 class="textbox" v-html="this.customisedText"></h2>

                <div v-if="slide.layout == 'explanation'">
                    <button class="buttonclass doesthishappen" @click="openExternalLink(slide.explanation.link)">
                        {{ slide.explanation.text }}
                    </button>
                </div>

                <TruthCheck v-if="slide.truthCheck" :truth="slide.truth" />

                <div v-if="slide.responseType === 'simple'">
                    <button class="buttonclass special" @click="setDesiredPost(slide.choices[0])">
                        Ok, next
                    </button>
                </div>
                <div v-else-if="slide.responseType === 'multiple'">
                    <template v-for="choice in slide.choices" :key="choice.goTo">
                        <button class="buttonclass special" @click="setDesiredPost(choice)">{{ choice.text }}</button>
                    </template>
                </div>
                <div v-else-if="slide.responseType === 'multipleEndScreen'">
                    <template v-for="choice in slide.choices" :key="choice.goTo">
                        <button v-if="choice.external" class="buttonclass special" @click="openExternalLink(choice.goTo)">{{
                            choice.text
                        }}</button>
                        <button v-else-if="choice.goTo == './resist.html'" class="buttonclass resist"
                            @click="openLinkInSameWindow(choice.goTo)">{{
                                choice.text
                            }}</button>
                        <button v-else class="buttonclass special" @click="setDesiredPost(choice)">{{
                            choice.text
                        }}</button>
                        <SharePopup v-if="choice.goTo=='./resist.html'" :win=false />
                    </template>
                </div>
            </div>
        </div>
    </div>

    <CustomParticles v-if="slide.layout === 'win'" />
</template>

<script>
import CustomParticles from "./CustomParticles.vue";
import TruthCheck from "./TruthCheck.vue";
import SharePopup from "./SharePopup.vue";
import "../assets/storyStyle.css";


export default {
    name: 'StoryPage',
    components: {
        CustomParticles,
        TruthCheck,
        SharePopup
    },
    emits: ["advance-to-next-slide"],
    props: {
        customText: {
            type: String,
            default: ""
        },
        slide: {
            type: Object,
            default() {
                return {

                    slideID: 0,
                    layout: "explanation",
                    content: {
                        "text": "DummyText"
                    },
                    responseType: "simple",
                    choices: [],
                    truthCheck: false
                }
            }
        }
    },
    methods: {
        setDesiredPost(choice) {
            this.$emit("advance-to-next-slide", choice)
        },
        openExternalLink(link) {
            window.open(link, '_blank')
        },
        openLinkInSameWindow(link) {
            window.open(link, '_self')
            localStorage.removeItem("slide")
            localStorage.removeItem("score")
            localStorage.removeItem("customText")
            localStorage.removeItem("prevFormattedScore")
        }
    },
    computed: {
        customisedText() {
            try {
                return eval(this.slide.content.text)
            }
            catch {
                return this.slide.content.text
            }
        },
        slideImage() {
            if (this.slide.img) {
                return this.slide.img
            }
            else {
                return this.slideImage
            }
        }
    },
    data() {
        return {
            dialog: false,
        }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
