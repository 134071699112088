<template>
  <Line id="my-chart-id" :options="chartOptions" :data="chartData" height="100" width="260" />
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale)

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    formattedScore: {
      type: Number,
      default: 45.24
    }
  },
  data() {
    return {
      past_price: [20.3, 25, 28, 26.4, 30, 34, 36, 31, 33, 35.24, 35, 38, 36.4, 40, 44, 46, 41, 43, 45.24, 35, 38, 36.4, 40, 44, 46, 41, 43, 45.24],
      chartOptions: {
        responsive: false,
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          point: {
            radius: 0
          },
          line: {
            borderColor: '#F99F1D'
          }
        },
        scales: {
          y: {
            min: 0,
            suggestedMax: 65,
            ticks: { display: false },
            grid: { display: false },
            display: false
          },
          x: {
            ticks: { display: false },
            grid: { display: false },
            display: false
          }
        },
        animation: {
          easing: 'easeOutSine',

          x: {
            duration: 400,
          },
          y: {
            duration: 0
          }
        }
      }
    }
  },
  watch: {
    formattedScore() {
      let prevScore = this.past_price[this.past_price.length-1]
      let p1 = (prevScore * 2.0 + this.formattedScore)/3.0 + (Math.random() - 0.5) * 8
      let p2 = (prevScore + this.formattedScore * 2.0 )/3.0 + (Math.random() - 0.5) * 8
      this.past_price.push(p1)
      this.past_price.push(p2)
      this.past_price.push(this.formattedScore)
    }
  },
  computed: {
    chartData() {
      let _tmpArray = []
      _tmpArray = this.past_price.map((x) => x)
      return {
        labels: _tmpArray,
        datasets: [{ data: _tmpArray }]
      }
    }
  }
}
</script>
