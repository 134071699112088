<template>
    <header @click="toggle" class="faqtitle">
      <h2><img :style="arrowStyle"  :src="require('../assets/'+image+'.png')"> <slot name="header"></slot></h2>
    </header>
    <main :style="displayStyle" class="faqmain">
      <slot name="default"></slot>
    </main>
</template>

<script>
export default {
  name: 'FaqExpandable',
  data() {
    return {
      open: false,
      image: "arrowright"
    }
  },
  methods: {
    toggle() {
      this.open = !(this.open)
      if (this.open) {
        this.image = "arrowdown"
      } else {
        this.image = "arrowright"
      }
    }
  },
  computed: {
    displayStyle() {
      return this.open ? "display: block" : "display: none"
    },
    arrowStyle() {
      return this.open ? "width: 15px; margin: 3px 0px" : "height: 15px; margin: 0px 3px"
    }
  }
}
</script>


<style>

h2 {
	margin-top: 20px;
	color: #f99f1d;
  font-size: larger;
  cursor: pointer;
}


.arrow{
  margin-right: 8px;
}

</style>