<template>
  <div class="statsbox">
    <div class="chartbox">
      <LineChart :formattedScore="formattedScore"/>
     <!--<LineChart />-->
    </div>
    <div class="rightbox">
      <p class="stockprice"> Stock price:</p>
    <p class="price">${{ ( (tweened )  ? tweened.toFixed(2) : 0 ) }}</p>
  <div class="pcchange" v-if="pc_change > 0">
     <img :src="require('../assets/up.svg')" class="landing_image">
     +{{ pc_change.toFixed(1) }} %
    </div>
    <div class="pcchange" v-else>
      <img :src="require('../assets/down.svg')" class="landing_image">
      {{ pc_change.toFixed(1) }} %
    </div>
  </div>

  </div>
</template>
  
<script>

import gsap from 'gsap'

import LineChart from './LineChart'

export default {
  name: 'ScoreCounter',
    components: {
      LineChart
    },
  data() {
    return {
      tweened: 45.24,
      pc_change: 5.2,
      prevFormattedScore: 43
    }
  },
  props: {
    score: {
      type: Number,
      default: 100
    }
  },
  watch: {
    score() {
      this.updatePcChange()
      gsap.to(this, { duration: 0.5, tweened: this.formattedScore || 0 })
    }
  },
  computed: {
    formattedScore() {
      let exponentedScore = 1.1 ** Number(this.score)
      return Number(exponentedScore.toFixed(2))
    }
  },
  methods: {
    updatePcChange() {
      localStorage.setItem("prevFormattedScore", JSON.stringify(this.prevFormattedScore))
      this.pc_change = 100*((this.formattedScore - this.prevFormattedScore)/this.prevFormattedScore)
      this.prevFormattedScore = this.formattedScore
    },
  },
  mounted() {
    let resumedFormattedScore = localStorage.getItem("prevFormattedScore")
    if (resumedFormattedScore === null) {
      this.prevFormattedScore = 43
      this.pc_change = 5.2
      this.tweened = 45.24
    }
    else {
      this.prevFormattedScore = JSON.parse(resumedFormattedScore)
      this.tweened = JSON.parse(resumedFormattedScore)
    }
  }
}

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stockprice {
  color: white;
  font-size: smaller;
  overflow: hidden;
  min-width: 100px
}
.price {
  color: #F99F1D;
  font-size: larger;
}

.pcchange {
  color: white;
  font-size: smaller;
}

.chartbox{
  font-size: x-large;
  vertical-align: middle;
  max-height: 84px;
  padding-right: 10px;
}

.rightbox {
  padding: 7pt;
}

.statsbox {
  background-color: #05204A;
  border: black;
  /* width: fit-content; */
  /* margin-bottom: 30pt; */
  align-content: right;
  display: flex;
  justify-content: right;
  z-index: 2;
  width: 100vw;
  padding-right: calc(max((50vw - 194px), 0px))
}
</style>
  