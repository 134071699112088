<template>
  <v-app class="houseScroll">

    <div class="textcontent">
      <!--<button class="closebutton" onclick="location.href = './index.html'">x</button>-->
      <img class="closebutton" onclick="location.href = './index.html'" src="./x.png" type="button">

      <h1 class="spaceout">Welcome to <b>the Fossil Game</b> </h1>
      <p class="spaceout">
        <b>The Fossil Game</b> is a "choose your own adventure" browser game where you experience - with
        real-life examples - how fossil-fuel multinationals make decisions that harm people and the environment because of
        incentives in our current economic system and shareholder capitalism.
      </p>


      <p class="spaceout">
        <i>We'd like to know who we are reaching with this game - help us by filling in <a
            href="https://framaforms.org/the-fossil-game-survey-1685362861" target="_blank">this survey</a></i>
      </p>

      <FaqExpandable>
        <template #header>
          How does the game work?
        </template>
        <template #default>
          <p class="spaceout">You, the player, take over the operations of a big fossil-fuel multinational. You have mines
            and
            boreholes everywhere, supply to the whole world, and employ thousands of people. Your goal is to make a profit
            and
            keep the company's share price up to keep shareholders happy.
          </p>
          <p class="spaceout">
            You are confronted with crises and difficult decisions. Can you do good <i>and</i> keep your shareholders
            happy?
            You learn the consequences of your choices for the environment and the local people at extraction sites, and
            also
            for the company's share price and your shareholders. With the current system of shareholder capitalism, there
            are
            often not enough incentives to do the right thing.</p>

        </template>
      </FaqExpandable>


      <FaqExpandable>
        <template #header>
          This game is biased!
        </template>
        <template #default>
          <p class="spaceout">Obviously, the storyline is fictional and simplified to fit our 21st century attention
            spans. The news articles we link to in our fact checks, however, are from real life. These are not even rare
            exceptions: there are so many examples of fossil multinationals acting unethically that it could be argued
            that ethical
            behaviour is the exception.</p>
          <p class="spaceout">Fossil companies spend millions on PR to convince you that they are responsible and ethical,
            are working hard to be greener and cleaner or that it is in fact your <a
              href="https://www.youtube.com/watch?v=vqZVCEnY-Us" target="_blank">"carbon footprint"</a> which is
            responsible for
            their behaviour. This game simply presents the wide body of evidence to the contrary.
          </p>

        </template>
      </FaqExpandable>


      <FaqExpandable>
        <template #header>
          Why make this game?
        </template>
        <template #default>
          <p class="spaceout">
            There is a huge body of evidence that corporations operating in the Global South exploit weak oversight and
            trigger-happy governments to maximise their profits.
            This has deadly consequences for the environment, the planet, and not least those who push back.
            Global Witness reports on the killing of environmental defenders:
          </p>

          <p class="spaceout quote">
            “Many threats and attacks against land and environmental defenders occur after communities voice their
            concerns about companies and their projects affecting their rights, including to their land. In their pursuit
            of profit, decades of research by human rights organisations document how companies and financial institutions
            have failed to respect communities and defenders impacted by their value and investment chains. Worse still,
            some global companies have been known to dupe well-meaning and conscientious consumers with misleading
            sustainability brochures, neglecting to mention corporate human rights abuses linked to their global
            operations.
            (<a href="https://www.globalwitness.org/en/campaigns/environmental-activists/last-line-defence/"
              target="_blank">source</a>)
          </p>

          <p class="spaceout">
            Through tying various aspects of fossil-fuel multinationals' behaviour together in a game and providing links
            to reliable reporting, we offer the player the opportunity to explore this broad and possibly overwhelming
            topic
            at their own pace.
          </p>

        </template>
      </FaqExpandable>


      <FaqExpandable>
        <template #header>
          What can I do to help?
        </template>
        <template #default>
          <p class="spaceout">
            Fossil multinationals are powerful, and seem to be untouchable. However, ordinary people <i>can</i> make a
            difference. <a href="./resist.html">Read how</a>
          </p>

        </template>
      </FaqExpandable>


      <FaqExpandable>
        <template #header>
          Where is part 2?
        </template>
        <template #default>
          <p class="spaceout">We - activists from the Netherlands - have developed one
            storyline about how these companies operate in the Global South.</p>


          <p class="spaceout">We want to develop a second storyline, which takes place in the Global North and revolves
            around
            lobbying, greenwashing, climate misinformation, and PR through museums, schools and universities. We would
            also like to
            translate the game to Dutch and other languages. </p>
          <p class="spaceout">However, we have to eat and pay our bills. If you're interested in removing this obstable
            please
            <a href="mailto:fossilgame@proton.me">get in touch!</a>
          </p>


        </template>
      </FaqExpandable>

      <div class="endDiv middle">
        <button class="buttonclass stuffOnTopIndex" onclick="location.href='./menu.html'" type="button">
          Play the game!</button>
      </div>
    </div>

  </v-app>
</template>

<script>
import vuetify from './plugins/vuetify'
import './assets/style.css';
import FaqExpandable from './components/FaqExpandable.vue';


export default {
  name: 'AppFAQ',
  components: {
    FaqExpandable
  },
  data() {
    return {
      dialog: false,
      inMiddleOfGame: false,
      hasPlayedOnce: false
    }
  },
  methods: {
  },
  mounted() {
    if (localStorage.getItem("slide") === null) {
      this.inMiddleOfGame = false
    }
    else {
      this.inMiddleOfGame = true
    }
    if (localStorage.getItem("playedOnce") === null) {
      this.hasPlayedOnce = false
    }
    else {
      this.hasPlayedOnce = true
    }
  }

}

</script>
