<template>
  <v-app class="house non-menu">
    <ScoreCounter :score="score" />
    <StoryPage :slide="currentSlide" :customText="customText" @advance-to-next-slide="advanceToNextSlide" />
  </v-app>
</template>

<script>
import vuetify from './plugins/vuetify'
import ScoreCounter from './components/ScoreCounter.vue'
import StoryPage from './components/StoryPage.vue'
import story from './story.json';
import './assets/style.css';

export default {
  name: 'App',
  components: {
    ScoreCounter,
    StoryPage,
  },
  data() {
    return {
      score: 40,
      img: "office_hexagon_small.png",
      customText: "",
      chapters: story.chapters,
      slides: [],
      currentChapter: 0,
      slideID: 0,
      chapterTitle: "DummyTitle",
      currentSlide: {
        "slideID": 0,
        "img": "war.png",
        "layout": "",
        "content": {
          "text": "DummyText"
        },
        "responseType": "simple",
        "choices": [],
        "truthCheck": false
      }
    }
  },
  methods: {
    initialiseStory() {
      this.slides = this.chapters[this.currentChapter].slides
      this.chapterTitle = this.chapters[this.currentChapter].title
      let resumeSlide = localStorage.getItem("slide")
      if (resumeSlide === null) {
        this.currentSlide = this.slides[0]
        this.slideID = this.slides[0].getSlideFromID
      }
      else {
        this.getSlideFromID(JSON.parse(resumeSlide))
        this.customText = localStorage.getItem("customText")
        this.score = JSON.parse(localStorage.getItem("score"))
      }
    },
    getSlideFromID(newID) {
      //TODO: change chapter if new chapter
      this.currentSlide = this.slides.filter(element => element.slideID == newID)[0]
      // TODO: add error handler if next slide does not exist. (e.g. go to start or page not found)
      if (!(this.currentSlide)) {
        this.currentChapter = this.chapters.filter(element => element.chapterID == newID / 1000)[0]
        localStorage.removeItem("slide")
        localStorage.removeItem("score")
        localStorage.removeItem("customText")
        localStorage.removeItem("prevFormattedScore")
        window.location.href = "./menu.html"
      }
      this.slideID = this.currentSlide.slideID
      localStorage.setItem("slide", JSON.stringify(this.slideID))
    },
    advanceToNextSlide(newSlide) {
      this.getSlideFromID(newSlide.goTo)
      { { newSlide.variables } }
      eval(newSlide.variables)
      localStorage.setItem("score", JSON.stringify(this.score))
      localStorage.setItem("customText", this.customText)
      localStorage.setItem("playedOnce", "played")
      
    }
  },
  mounted() {
    this.initialiseStory()
  }

}

</script>

<style>





</style>
